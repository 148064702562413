import React from 'react';
import { useEffect, useRef } from 'react';
import fondo from '../../assets/img/candidate.jpeg'
import logoMW from '../../assets/img/MATCHWORKING-07.png'

const arrayToText = (arr) => {
  return arr.map(item => `${item}`).join(", ");
};

function capitalizeFirstLetter(text) {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
  const words = text.split(' ');
  let line = '';
  let yPos = y;

  words.forEach(word => {
    let testLine = line + word + ' ';
    let metrics = ctx.measureText(testLine);
    let testWidth = metrics.width;

    if (testWidth > maxWidth && line !== '') {
      ctx.fillText(line, x, yPos);
      line = word + ' ';
      yPos += lineHeight;
    } else {
      line = testLine;
    }
  });

  ctx.fillText(line, x, yPos);
};

function drawCenteredText(ctx, text, canvasWidth, yPosition) {
  // Mide el ancho del texto
  const textWidth = ctx.measureText(text).width;

  // Calcula la posición x para que el texto quede centrado
  const xPosition = (canvasWidth - textWidth) / 2;
  
  ctx.fillText(text, xPosition, yPosition);
}


function CanvasPosition({ data }) {
  console.log(data)
  const canvasRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const scale = window.devicePixelRatio;

    // Tamaño del canvas
    canvas.width = 500;
    canvas.height = 500;

    // Definir el máximo ancho para el texto
    const maxWidth = 300;

    // Fondo Blanco
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Imagen de fondo
    const bgImage = new Image();
    bgImage.src = fondo;
    bgImage.onload = () => {
      ctx.globalAlpha = 0.3; // Opacidad de la imagen de fondo
      ctx.drawImage(bgImage, 0, 85, 500, 275); // Posición y tamaño
      ctx.globalAlpha = 1; // Restablecer opacidad
    };

    const logo = new Image();
    logo.src = logoMW;
    logo.onload = () => {
      ctx.drawImage(logo, 450, 5, 50, 50); // Posición y tamaño      
    };

    // Fondo azul para el título
    ctx.fillStyle = "#26455b";
    ctx.fillRect(0, 0, canvas.width, 50);

    // Fondo naranja
    ctx.fillStyle = "#F0810F";
    ctx.fillRect(0, 50, canvas.width, 35);

    // Texto de "Oferta de Empleo"
    ctx.font = "bold 16px Arial";
    ctx.fillStyle = "#f4f4f4";
    ctx.fillText("¡E S T A M O S", 10, 20);

    // Texto de "Oferta de Empleo"
    ctx.font = "bold 16px Arial";
    ctx.fillStyle = "#f4f4f4";
    ctx.fillText("C O N T R A T A N D O!", 10, 40);

    // Texto del puesto
    ctx.font = "bold 18px Arial";
    // ctx.fillText(data.profile_positionToHire, 150, 70);

    drawCenteredText(ctx, data.profile_positionToHire, canvas.width, 70);

    ctx.font = "bold 10px Arial";
    drawCenteredText(ctx, data.profile_workLocation.profile_city, canvas.width, 80);

    // Sección de requisitos
    ctx.font = "bold 12px Arial";
    ctx.fillStyle = "#000000";
    ctx.fillText("REQUISITOS:", 20, 120);

    ctx.font = "bold 12px Arial";
    ctx.fillText("FORMACION:", 20, 150);
    ctx.font = "12px Arial";
    ctx.fillText(data.profile_education.title, 20, 165);

    ctx.font = "bold 12px Arial";
    ctx.fillText("EXPERIENCIA:", 20, 185);
    ctx.font = "12px Arial";
    ctx.fillText(data.profile_experience.profile_timeExperience, 20, 200);

    // Unir competencias
    const competencies = [
      ...data.profile_competencies.personalSkills.map(skill => skill.nombre),
      ...data.profile_competencies.technicalSkills.map(skill => skill.nombre)
    ];
    const competenciesJoin = `${competencies.join(', ')}`;
    ctx.font = "bold 12px Arial";
    ctx.fillText("CONOCIMIENTOS:", 20, 220);
    ctx.font = "12px Arial";
    wrapText(ctx, capitalizeFirstLetter(competenciesJoin), 20, 235, 380, 20)
    // ctx.fillText(formattedKnowledge, 20, 210);

    // Sección de beneficios
    ctx.font = "bold 12px Arial";
    ctx.fillText("BENEFICIOS:", 20, 275);
    ctx.font = "12px Arial";
    let currentY = 290;
    data.profile_companyBenefits.forEach(item => {      
      wrapText(ctx, `- ${capitalizeFirstLetter(item)}`, 20, currentY, 380, 20);            
      currentY += 20;
  });
    // wrapText(ctx, capitalizeFirstLetter(data.profile_companyBenefits), 20, 290, 380, 20)
    // jobData.benefits.forEach((benefit, index) => {
    //   ctx.fillText(`• ${benefit}`, 20, 360 + index * 20);
    // });

    // Fondo azul para la agencia y ciudad
    ctx.fillStyle = "#F0810F";
    ctx.fillRect(0, 360, canvas.width, 35);

    // Texto de la agencia
    ctx.font = "bold 14px Arial";
    ctx.fillStyle = "#ffffff";
    ctx.fillText("Aplica a: reclutamiento@match-working.com", 20, 380);

  }, [data]);

  return (
    <div className='overflow-auto h-auto sm:h-[80vh] md:h-[150vh] lg:h-[100vh]'>
      <canvas ref={canvasRef} className='w-full h-auto text-black'></canvas>
    </div>
  );
}


export default CanvasPosition