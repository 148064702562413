import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from "./CVUploadComponentStyles";

import { ArrowBack, HourglassTop, UploadFile } from "@mui/icons-material";
import AcceptedFileComponent from "./AcceptedFileComponent";
import RejectedFileComponent from "./RejectedFileComponent";

import Swal from "sweetalert2";
import { useMutation } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid'

import { CircularProgress } from "@mui/material";

import { copyToClipboardProfile, cvFilesUrl } from "../../enviroment/enviroment-dev";
import showAlert from "../../utilities/alerts";
import CopyLink from "../../utilities/copyToClipboard";
import apiClient from "../../utilities/interceptorConfig";
import { useLoadingTimer } from "../../utilities/loadingTimer";
const MAX_FILE_SIZE = 6 * 1024 * 1024

const CVUploadComponent = ({ setPage, id, setProcessedComplete }) => {
    const [files, setFiles] = useState([]);
    const [badFileNotification, setBadFileNotification] = useState(false) //notificación de archivos erroneos subidos en dropzone
    const [loading, setLoading] = useState(false)
    const [sendComplete, setSendComplete] = useState(false);
    const [fileSentCount, setFileSentCount] = useState(0);
    const [tasks, setTasks] = useState([]);
    const [allowMonitor, setAllowMonitor] = useState(false); //permite monitoreo de estado de procesamiento de cvs solo si se han creado las tasks

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        acceptedFiles.forEach((file) => {
            file.id = uuid() //id de monitoreo enfront
            setFiles((prevState) => [...prevState, file]);
        });
    }, []);

    const {
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'image/jpeg': ['.jpg', '.jpeg'],
        },
        maxSize: MAX_FILE_SIZE,
        onDrop,
        disabled: loading
    })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
    ]);

    const removeFile = (id) => setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
    const removeTask = (id) => setTasks(prevTasks => prevTasks.filter(task_id => task_id !== id));
    const acceptedFileItems = files.map((file, index) => (
        <AcceptedFileComponent key={index} file={file} removeFile={removeFile} loading={loading} />
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
        <RejectedFileComponent key={index} file={file} />
    ));

    useEffect(() => {
        setBadFileNotification(fileRejections.length > 0);
    }, [fileRejections])

    const cleanFiles = () => setFiles([])

    const sendFilesMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData();
            files.map(file => formData.append('files', file));
            return await apiClient.post(`${cvFilesUrl}uploadfiles/?job_position_profile=${id}`, formData, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        onMutate: () => {
            files.forEach((file) => {
                file.error = ""
                file.processing = true
            })
            setProcessedComplete(false)
            setFileSentCount(0)
            setLoading(true)
        },
        onSuccess: (data, files) => {
            setTasks(data.data.task_ids)
            files.forEach((file, index) => {
                file.task_id = data.data.task_ids[index]
            })
            setAllowMonitor(true)
        },
        onError: () => {
            showAlert.error("No existe comunicación con el servidor.")
            setLoading(false)
            files.forEach((file) => {
                file.error = "No existe comunicación con el servidor"
                file.processing = false
            })
        }
    });

    const monitorMutation = useMutation({
        mutationFn: async (task_id) => {
            return await apiClient.get(`${cvFilesUrl}results/?task_id=${task_id}`, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json '
                }
            });
        },
        onSuccess: (response, task) => {
            console.log('Mutation', response, task)
            if (response.data.status === "pending") return;
            switch (response.data.status) {
                case "SUCCESS":
                    if (response.data.result.error) {
                        let errorDetail = "";
                        switch (response.data.result.error.status_code) {
                            case 400: errorDetail = response.data.result.error.detail; break; //Mensaje Explicito
                            case 492: errorDetail = "Archivo con problemas para interpretación"; break; //Mensaje no explicito
                            case 500: errorDetail = "Ocurrió un error al subir archivo"; break;
                            default: errorDetail = response.data.result.error.detail || `Error`
                                break;
                        }
                        const failedFile = files.filter(file => file.task_id === task)[0]
                        failedFile.processing = false
                        failedFile.error = errorDetail
                        setFiles(prevFiles => prevFiles.map(f => f.id === failedFile.id ? failedFile : f));
                        removeTask(failedFile.task_id)
                        return
                    }
                    if (response.data.result.code === '200') {
                        const successFile = files.filter(file => file.task_id === task)[0]
                        successFile.processing = false
                        successFile.success = true
                        setFiles(prevFiles => prevFiles.map(f => f.id === successFile.id ? successFile : f));

                        setTimeout(() => {
                            removeFile(successFile.id)
                            removeTask(successFile.task_id)
                            setFileSentCount(prev => prev + 1)
                        }, 1000);
                    }
                    break;
                default:
                    const failedFile = files.filter(file => file.task_id === task)[0]
                    failedFile.processing = false
                    failedFile.error = "Error en el Servidor"
                    removeTask(failedFile.task_id)
                    setFiles(prevFiles => prevFiles.map(f => f.id === failedFile.id ? failedFile : f));
                    break;
            }
        },
        onError: (error, task, context) => {
            const failedFile = files.filter(file => file.task_id === task)[0]
            failedFile.processing = false
            failedFile.error = "Error en comunicación con servidor:" + error
            removeTask(failedFile.task_id)
            setFiles(prevFiles => prevFiles.map(f => f.id === failedFile.id ? failedFile : f));
        }
    })
    const elapsedTime = useLoadingTimer(loading);
    const calculateTimeSaved = (files, time = 4) => {
        console.log("elapsedTime:", elapsedTime)
        const totalMinutes = Math.max(0, files) * time;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        const hoursText = hours === 1 ? `${hours} hora` : `${hours} horas`;
        const minutesText = minutes === 1 ? `${minutes} minuto` : `${minutes} minutos`;
        const elapsedMinutes = `${Math.floor(elapsedTime / 60000)} minutos`; 

        if (hours > 0 && minutes > 0) {
            return `${hoursText} y ${minutesText}, ahora tomó ${elapsedMinutes}`;
        } else if (hours > 0) {
            return `${hoursText}, ahora tomó ${elapsedMinutes}`;
        } else {
            return `${minutesText}, ahora tomó ${elapsedMinutes}`;
        }
}
useEffect(() => {
    if (sendComplete && !allowMonitor) {
        if (fileSentCount === 0) {
            Swal.fire({
                title: "Archivos no procesados",
                icon: "warning",
                confirmButtonText: `Volver a subir archivos`,
                confirmButtonColor: "#F0810F",
            })
        }
        else {
            Swal.fire({
                title: `Archivos Procesados: ${fileSentCount} `,
                icon: "info",
                text: `Lo que te habría llevado alrededor de ${calculateTimeSaved(fileSentCount)} Imagina lo que podrías lograr con el tiempo que optimizas.`,
                showDenyButton: true,
                confirmButtonText: "Ver Resultados",
                denyButtonText: `Subir más archivos`,
                confirmButtonColor: "#011A27",
                denyButtonColor: "#F0810F",
            }).then((result) => {
                if (result.isConfirmed) {
                    setPage(4)
                }
            });
        }
    }
}, [sendComplete, fileSentCount, allowMonitor, setPage, elapsedTime])

useEffect(() => {
    //Fin de Procesamiento - Reseteo de estado
    if (tasks.length === 0 && allowMonitor) {
        setSendComplete(true)
        setLoading(false)
        setTasks([])
        setAllowMonitor(false)
        setProcessedComplete(true)
        return;
    }

    const executeMutation = async () => {

        if (tasks && tasks.length > 0) {
            try {
                await Promise.all(tasks.map(task => monitorMutation.mutateAsync(task)));
            } catch (error) {
                console.error('Error on monitoring tasks:', error);
            }
        }
    };
    let timeout;

    if (tasks && allowMonitor) {
        timeout = setTimeout(executeMutation, 22000);
    }
    return () => {
        clearTimeout(timeout);
    };
}, [tasks, allowMonitor, monitorMutation]);

const handleUpload = async () => sendFilesMutation.mutateAsync(files);

const handleProfileStep = () => {
    setPage(1)
}

return (
    <div
        className="font-sans rounded-md  
        flex items-center justify-center
        shadow-sm text-white 
        w-full max-w-md md:max-w-2xl lg:max-w-4xl mx-auto
        text-wrap break-words
        "
    >
        <div
            className="flex flex-col items-start  bg-[rgb(1,26,39)] text-white px-7 md:px-20 py-10 rounded-lg w-full"
        >   <div className="flex justify-between w-full items-center">
                <button
                    className={`bg-[#F0810F] text-white text-xl 
                            rounded-md p-2 font-semibold hover:scale-105`}
                    type="submit"
                    onClick={handleProfileStep}
                >
                    <ArrowBack fontSize="large" /> Perfil de cargo
                </button>
                <CopyLink link={`${copyToClipboardProfile}/${id}`} />
            </div>
            <h1 className="mt-3 text-left"
                style={{ fontSize: 'clamp(1.25rem, 2.5vw, 2.25rem)' }}
            >
                Subir Documentos
            </h1>
            <h4 className="mt-2 text-left font-light text-[20px]"
            >Sube las hojas de vida de los candidatos en los siguientes formatos: .pdf, .docx, .jpg/jpeg. Tamaño máximo: 6MB</h4>

            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {
                    loading
                        ? <HourglassTop style={{ fontSize: 90, color: style.borderColor, transition: style.transition, opacity: '50%' }} />
                        : <UploadFile style={{ fontSize: 90, color: style.borderColor, transition: style.transition, opacity: '50%' }} />
                }
            </div>
            <p className="text-gray-400">
                {
                    loading
                        ? "Estamos procesando los archivos. Espera un momento por favor."
                        : "Carga los documentos que creas necesarios."
                }

            </p>
            <button
                onClick={handleUpload}
                className="w-full bg-[#F0810F] text-white text-3xl 
                rounded-md p-2 font-semibold hover:scale-105"
                type="submit"
                style={{ fontSize: 'clamp(1.25rem, 2.5vw, 2.25rem)' }}
                disabled={files.length === 0 || loading}
            >
                {
                    loading
                        ? <CircularProgress style={{ color: 'white', fontSize: 72 }} />
                        : "Enviar"
                }
            </button>
            <aside className="mt-1 w-full">
                {
                    files.length !== 0 && (
                        <div className="flex flex-row items-center justify-between">
                            <h4 className="mt-2 text-sm md:text-xl"
                            >
                                Archivos restantes: {files.length}
                            </h4>
                            <button onClick={cleanFiles} className={`text-sm ${loading ? " opacity-0" : ""}}`}
                                disabled={files.length === 0 || loading}
                            >
                                Limpiar
                            </button>
                        </div>
                    )
                }
                <ul className="pl-0">{acceptedFileItems}</ul>
                {
                    badFileNotification && (
                        <div className="text-sm md:text-xl">
                            <h4 >{fileRejections.length}{files.length === 1 ? " Documento Rechazado" : " Documentos Rechazados"}</h4>
                            <p className="font-thin">Estos documentos no cumplen el formato/tamaño esperado:</p>
                            <ul className="pl-0">{fileRejectionItems}</ul>
                            <button className="text-sm" onClick={() => setBadFileNotification(false)}>Aceptar</button>
                        </div>
                    )
                }
            </aside>
        </div>
    </div>
);
}

export default CVUploadComponent