//Componente para darle estilos generales a las rutas hijas desde el enrutador en el APP
import React, { useState, useEffect, useCallback } from 'react';
import Joyride from 'react-joyride';
import { TutorialContext } from '../utilities/TutorialStepsContext'

const MainContainer = ({ children }) => {
  const [steps] = useState([
    {
      target: '.step-1',
      content: 'Bienvenido a Matchworking. Explora y descubre mientras usas nuestros servicios cuando quieras',
      disableBeacon:true
    },
    {
      target: '.step-2',
      content: '¿Tienes algún problema?, comunícate con soporte técnico',
      disableBeacon:true
    },
    {
      target: '.step-3',
      content: 'Revisa la información de tu suscripción y cantidad de monedas.\n Nos complace tenerte, te obsequiamos 20 monedas ',
      disableBeacon:true
    },
    {
      target: '.step-4',
      content: 'Gestiona tu perfil y revisa el historial de compras',
      disableBeacon:true
    },
  ]);
  const [start, setStart] = useState(false)

  useEffect(() => {
    const tutoShown = localStorage.getItem('tutoShown'); // Verificar si completo el tuto de mainpage    
    if (!tutoShown) {
      setTimeout(() => setStart(true), 100);
    }
  }, [steps]);

  const startTutorial = useCallback(() => {
    setStart(true);
  }, []);

  const handleCallback = (data) => {
    const { status } = data;
    // console.log(data)
    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setStart(false);
      localStorage.setItem('tutoShown', 'true');
    }
  };

  return (    
      <TutorialContext.Provider value={{ steps, startTutorial }}>
        <main className='pt-20 flex flex-col min-h-screen'>
          <Joyride
            callback={handleCallback}
            steps={steps}
            run={start}
            continuous={true}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            // debug={true}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
            locale={{
              back: "Volver",
              close: "Cerrar",
              next: "Siguiente",
              last: "Terminar",
              skip: "Saltar"
            }}
            disableBeacon={true} 
          />
          {children}
        </main>
      </TutorialContext.Provider>    
  );
};

export default MainContainer;