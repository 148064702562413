import { useEffect, useState } from "react";

export const useLoadingTimer = (loading) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [startTime, setStartTime] = useState(null);

    useEffect(() => {
        let timer;
        if (loading) {            
            setStartTime(Date.now());
        } else if (startTime) {            
            const timePassed = Date.now() - startTime;
            setElapsedTime((prevTime) => prevTime + timePassed);
            setStartTime(null);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [loading]); 

    return elapsedTime;
};