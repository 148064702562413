import React, { useEffect, useState } from "react";
import logo from "./assets/img/logoMatchworkingSinFondo.png";
import userService from "../services/userService";
import showAlert from "../utilities/alerts";
import { useUser } from ".././VariablesContext";
import { PptxGenerator } from "../utilities/ad-pptx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from 'react-router-dom';

const LogIn = () => {
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false); // Estado para controlar la visibilidad de la contraseña
  const {setLoggedGuardian,setLoggedInUserId} =useUser();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await userService.getAutUser(formData);
      const userId = response.user_ID;

      if (userId) {
        setLoggedInUserId(userId);
        setLoggedGuardian(true);

        //console.log("Se guarda logged: ",logged);
        navigate('/profile-form');

      } else {
        showAlert.error(
          "Lo sentimos, las credenciales son incorrectas. Vuelve a intentarlo."
        );
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error.message);
      showAlert.error("No se pudo iniciar sesión.");
    }
  };

  useEffect(() => {
    const passwordInput = document.getElementById("password");
    const togglePasswordBtn = document.getElementById("togglePasswordBtn");

    const handleTogglePassword = () => {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        setShowPassword(true);
      } else {
        passwordInput.type = "password";
        setShowPassword(false);
      }
    };

    if (togglePasswordBtn) {
      togglePasswordBtn.addEventListener("click", handleTogglePassword);

      return () => {
        togglePasswordBtn.removeEventListener("click", handleTogglePassword);
      };
    }
  }, []); // <--- Este array vacío indica que el efecto se ejecuta solo una vez

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-36 w-auto"
          src={logo}
          alt="Logo-MatchWorking"
        />
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-[#063852]">
          Ingresa a tu cuenta
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">      
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-[#011A27] text-left"
            >
              Correo electrónico
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 text-[#011A27] shadow-sm ring-1 ring-inset ring-[#011A27] placeholder:text-[#011A27] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-[#011A27]"
              >
                Contraseña
              </label>
              <div className="text-sm">
                <a
                  href="/forgot-password"
                  className="font-semibold text-[#F0810f] hover:text-[#E6DF44] text-decoration-none"
                >
                  ¿Olvidaste tu contraseña?
                </a>
              </div>
            </div>
            <div className="mt-2 relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                value={formData.password}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 pr-10 text-[#011A27] shadow-sm ring-1 ring-inset ring-[#011A27] placeholder:text-[#011A27] focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  className="text-[#F0810f] hover:text-[#E6DF44] cursor-pointer"
                />
              </div>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-[#F0810f] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#E6DF44] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Iniciar sesión
            </button>
          </div>
        </form>     
        <p className="mt-10 text-center text-base font-bold text-gray-500">
          ¿No tienes una cuenta con nosotros?{" "}
          <a
            href="/sign-up"
            className="font-semibold leading-6 text-[#F0810f] hover:text-[#E6DF44] text-decoration-none"
          >
            Crea una ahora y te obsequiamos 20 monedas para que puedas usar nuestros servicios!
          </a>
        </p>   
      </div>      
    </div>
  );
};

export default LogIn;
